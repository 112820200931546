import { nav } from '../inits/navigation';

export class HeaderSkip {
    link: HTMLAnchorElement;
    target: HTMLElement;
    targetLocation: number;

    constructor(element: HTMLAnchorElement) {
        this.link = element;
        this.getDOM();
        this.init();
    }

    getDOM() {
        this.target = document.querySelector(this.link.getAttribute('href')) as HTMLElement;

        // Tag block container if the first child is full bleed so that it can jump to the correct location when triggered
        const blockContainer = document.querySelector('[data-blocks]') as HTMLElement;
        if (blockContainer) {
            const firstBlock = blockContainer.firstElementChild as HTMLElement;
            if (firstBlock && firstBlock.hasAttribute('data-full-bleed')) {
                const range = firstBlock.getAttribute('data-full-bleed');
                this.target.classList.add(`skip-target-spaced${range ? `-${range}` : ''}`);
            }
        }
    }

    init() {
        if (this.link && this.target) {
            const navClearance = nav.desktopNavActive() ? nav.desktopShort : nav.mobileShort;
            this.link.addEventListener('click', (e) => {
                e.preventDefault();
                this.targetLocation = this.target.offsetTop - navClearance;
                this.target.focus();
                window.scrollTo(0, this.targetLocation);
            });
        }
    }
}