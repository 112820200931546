import { nodeListToArray } from "../helpers/nodeListToArray";

export class PdfDownloadBlock {
    pdfCheckboxes: HTMLInputElement[];
    selectAllCheckbox: HTMLInputElement;
    downloadButton: HTMLButtonElement;
    loadingMessage: HTMLElement;
    pdfFileName: string;
    defaultPdfUrl: string;
    optionNotSelectedErrorContainer: HTMLElement;

    readonly hideErrorsClass = "validation-summary-valid";

    constructor(container: HTMLElement) {
        if (!container)
            return;

        const pdfFileName = container.querySelector("[data-pdf-filename]");
        if (pdfFileName) {
            this.pdfFileName = (pdfFileName as HTMLInputElement).value;
        }

        const defaultPdfUrl = container.querySelector("[data-default-pdf]");
        if (defaultPdfUrl) {
            this.defaultPdfUrl = (defaultPdfUrl as HTMLInputElement).value;
        }

        const optionNotSelectedErrorContainer = container.querySelector("[data-option-not-selected-error]");
        if (optionNotSelectedErrorContainer) {
            this.optionNotSelectedErrorContainer = optionNotSelectedErrorContainer as HTMLElement;
        }

        this.pdfCheckboxes = nodeListToArray(container.querySelectorAll("[data-pdf-checkbox]")) as HTMLInputElement[];
        this.pdfCheckboxes.forEach(checkbox => {
            checkbox.addEventListener("change", this.handleSelectAllState);
        });

        const downloadButton = container.querySelector("[data-download-button]") as HTMLInputElement;
        if (downloadButton) {
            this.downloadButton = downloadButton;
            this.downloadButton.addEventListener("click", this.downloadPdf);
        }

        const selectAllCheckbox = container.querySelector("[data-pdf-select-all]") as HTMLInputElement;
        if (selectAllCheckbox) {
            this.selectAllCheckbox = selectAllCheckbox;
            this.selectAllCheckbox.addEventListener("change", this.toggleSelectAll);
        }

        const loadingMessage = container.querySelector("[data-loading-message]") as HTMLElement;
        this.loadingMessage = loadingMessage;
        this.loadingMessage.style.display = "none";
    }

    toggleSelectAll = (e) => {
        if (this.selectAllCheckbox.checked) {
            this.pdfCheckboxes.forEach(item => {
                item.checked = true;
            });
        }
        else {
            this.pdfCheckboxes.forEach(item => {
                item.checked = false;
            });
        }
    }

    downloadPdf = (): void => {
        let selectedPdfUrls = this.getSelectedUrls();
        if (selectedPdfUrls.length > 0) {
            this.optionNotSelectedErrorContainer.classList.add(this.hideErrorsClass);

            if (this.defaultPdfUrl && this.defaultPdfUrl.length > 0) {
                selectedPdfUrls = [this.defaultPdfUrl].concat(selectedPdfUrls);
            }

            window.location.href = `/PdfDownloadContainerBlock/GetConcatenatedPdf/?pdfUrls=`
                + selectedPdfUrls.map(x => encodeURIComponent(x)).join("&pdfUrls=")
                + `&fileName=` + encodeURIComponent(this.pdfFileName);
            this.loadingMessage.style.display = "block";
        }
        else {
            this.optionNotSelectedErrorContainer.classList.remove(this.hideErrorsClass);
        }
    }

    handleSelectAllState = (): void => {
        const numberSelectedItems = this.getSelectedItems().length;
        if (numberSelectedItems == this.pdfCheckboxes.length) {
            this.selectAllCheckbox.checked = true;
        }
        else {
            this.selectAllCheckbox.checked = false;
        }
    }

    getSelectedItems = (): HTMLInputElement[] => {
        const selectedItems: HTMLInputElement[] = [];

        this.pdfCheckboxes.forEach(item => {
            if (item.checked) {
                selectedItems.push(item);
            }
        });

        return selectedItems;
    }

    getSelectedUrls = (): string[] => {
        const selectedItems = this.getSelectedItems();
        return selectedItems.map(item => item.value);
    }
}