import { HeaderSkip } from '../classes/headerSkip';
import { nav } from '../inits/navigation';
import { PdfDownloadBlock } from '../classes/pdfDownloadBlock';

const debounce = require('lodash.debounce');

const initAll = () => {
    const headerSkipLink = initHeaderSkip();
    initResizeHandlers(headerSkipLink);
    initPdfDownloadBlock();
}

const initHeaderSkip = () => {
    // Link in hero to skip down to content
    const headerSkipLinkEl = document.querySelector('[data-header-skip]') as HTMLAnchorElement;
    let headerSkip: HeaderSkip;
    if (headerSkipLinkEl) {
        headerSkip = new HeaderSkip(headerSkipLinkEl);
    }
    return headerSkip;
}

const initResizeHandlers = (headerSkipLink: HeaderSkip) => {
    window.addEventListener('resize', debounce(() => {
        if (headerSkipLink) {
            headerSkipLink.init();
        }
        if (nav) {
            nav.setClearance();
        }
    }, 50));
}

const initPdfDownloadBlock = () => {
    const pdfDownloadContainers = [].slice.call(document.querySelectorAll('[data-pdf-download-container]'));
    pdfDownloadContainers.forEach(container => {
        new PdfDownloadBlock(container);
    });
}

initAll();